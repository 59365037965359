// const isBrowser = typeof window !== "undefined"

export function getCheckoutId() {
  if (typeof window !== "undefined") {
    //  const test = "" || JSON.parse(localStorage?.getItem("checkout_id"))
    // console.log("test:", JSON.parse(localStorage?.getItem("checkout_id")) || "")
    return JSON.parse(localStorage?.getItem("checkout_id")) || ""
  }
  return ""
}

export function getCart() {
  if (typeof window !== "undefined") {
    const cartInfo = JSON.parse(localStorage?.getItem("cart"))

    return cartInfo?.length ? cartInfo : []
  }

  return []
}
