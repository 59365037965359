// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-template-blog-page-js": () => import("./../../../src/components/template/BlogPage.js" /* webpackChunkName: "component---src-components-template-blog-page-js" */),
  "component---src-components-template-product-details-js": () => import("./../../../src/components/template/ProductDetails.js" /* webpackChunkName: "component---src-components-template-product-details-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-find-us-js": () => import("./../../../src/pages/find-us.js" /* webpackChunkName: "component---src-pages-find-us-js" */),
  "component---src-pages-ibotta-js": () => import("./../../../src/pages/ibotta.js" /* webpackChunkName: "component---src-pages-ibotta-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */)
}

