import React, {
  createContext,
  useContext,
  useState,
  useReducer,
  useEffect,
} from "react"
import Client from "shopify-buy"
import addToMailChimp from "gatsby-plugin-mailchimp"
import { getCheckoutId, getCart } from "../lib/localStorageStuff"
import PopUpLoading from "../components/template/PopUpLoading"

const client = Client.buildClient({
  domain: process.env.GATSBY_SHOPIFY_STORE,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
})

const defaultOptions = {
  state: {
    cart: [],
    total: 0,
    lineItems: [],
    checkoutId: "",
    checkout: {},
    isCartOpen: false,
    isLoading: false,
    message: "",
    firstRender: true,
    removing: false,
    error: "",
  },
  initCheckout() {},
  getNewId() {},
  removeFromCart() {},
  updateCart() {},
  addToCart() {},
  addCoupon() {},
  subscribe() {},
  removeCoupon() {},
  toggleCart() {},
  cleanerAddToCart() {},
  cleanerRemoveFromCart() {},
}

const initialValues = {
  cart: getCart(),
  total: 0,
  checkoutId: getCheckoutId(),
  checkout: {},
  isCartOpen: false,
  isLoading: false,
  message: "",
  firstRender: true,
  removing: false,
  error: "",
}
// cart
// checkout

function globalReducer(state, action) {
  switch (action.type) {
    case "NEW_ID": {
      return {
        ...state,
        checkoutId: action.checkoutId,
        isLoading: false,
        checkout: action.checkout,
        total: action.total,
        firstRender: false,
        removing: false,
        error: "",
      }
    }
    case "CLEAR_ALL": {
      return {
        ...state,
        cart: [],
        total: 0,
        lineItems: [],
        checkoutId: "",
        checkout: {},
        isCartOpen: false,
        isLoading: true,
        message: "",
        removing: false,
        error: action.error,
      }
    }
    case "TOGGLE_LOADING": {
      return {
        ...state,
        isLoading: true,
        removing: action.removing || state.removing,
        error: "",
      }
    }
    case "ADD_PRODUCT_TO_CART": {
      //
      localStorage.setItem("last_added", JSON.stringify(new Date()))
      return {
        ...state,
        cart: action.cart,
        checkout: action.checkout,
        isLoading: false,
        total: action.total,
        removing: false,
        error: "",
      }
    }

    case "UPDATE_CHECKOUT": {
      return {
        ...state,
        checkout: action.checkout,
        isLoading: false,
        total: action.total,
        cart: action.cart,
        error: "",
      }
    }
    case "TOGGLE_CART": {
      return { ...state, isCartOpen: action.open, error: "" }
    }
    case "START_TOTAL": {
      return { ...state, total: action.total, error: "" }
    }
    case "UPDATE_CART": {
      localStorage.setItem("cart", JSON.stringify([...action.cart]))

      return { ...state, cart: action.cart, error: "" }
    }

    case "SET_MESSAGE": {
      return { ...state, message: action.message, error: "" }
    }

    case "START_CART": {
      return { ...state, cart: action.cart, error: "" }
    }

    default: {
      return state
    }
  }
}

const StoreContext = createContext(defaultOptions)

export function StoreProvider({ children }) {
  // this is state is in case we have a faulty id in localStorage. Will be handled inside the initCheckout func
  const [localError, setLocalError] = useState(false)

  const [state, dispatch] = useReducer(globalReducer, initialValues)

  useEffect(() => {
    // initCheckout()

    if (typeof window !== "undefined") {
      window.addEventListener("beforeunload", beforeClose)
      const now = new Date()
      const lastVisit = JSON.parse(localStorage.getItem("last_visit")) || 0

      const val = now.getTime() - new Date(lastVisit).getTime()

      const valToCompare = val / 1000 / 60 / 60 / 24
      // const valToCompare = val / 1000 / 60
      if (valToCompare > 1) {
        startFresh()
      } else {
        startCheckout()
      }
    }
    function beforeClose() {
      localStorage.setItem("last_visit", JSON.stringify(new Date()))
    }
    return () => {
      window.removeEventListener("beforeunload", beforeClose)
    }
  }, [])

  //initializes the checkout. makes sure we have the checkout (shopify data) controlled in state

  function toggleCart() {
    dispatch({ type: "TOGGLE_CART", open: !state.isCartOpen })
  }

  // ADD STUFF to LOCALSTORAGE
  useEffect(() => {
    function beforeCloseTab() {
      localStorage.setItem("cart", JSON.stringify(state.cart))
    }
    if (typeof window !== "undefined") {
      window.addEventListener("beforeunload", beforeCloseTab)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("beforeunload", beforeCloseTab)
      }
    }
  }, [state.cart])

  async function getNewCheckout(id) {
    const newCheckout = await client.checkout.fetch(id)
    if (newCheckout?.completedAt) {
      return startFresh()
    }
    return newCheckout
  }

  async function startCheckout() {
    dispatch({ type: "TOGGLE_LOADING" })
    try {
      const currentCheckoutId =
        typeof window !== "undefined" ? state.checkoutId : null
      if (!currentCheckoutId) {
        return startFresh()
      }
      const newCheckout = await getNewCheckout(currentCheckoutId)
      if (!newCheckout) {
        return
      }

      dispatch({
        type: "NEW_ID",
        checkout: newCheckout,
        checkoutId: newCheckout.id,
        total: newCheckout.totalPrice,
      })
    } catch (error) {
      console.log("error:", error)
      return startFresh()
    }
  }

  function clearAllLocalStorage() {
    if (typeof window !== "undefined") {
      localStorage.removeItem("checkout_id")
      localStorage.removeItem("cart")
      localStorage.removeItem("last_added")
    }
  }

  async function startFresh(error = "") {
    clearAllLocalStorage()
    // console.log("START FRESH< TOTALLY")
    dispatch({ type: "CLEAR_ALL", error })
    try {
      const newCheckout = await client.checkout.create()
      if (typeof window !== "undefined") {
        localStorage.setItem("checkout_id", JSON.stringify(newCheckout.id))
      }
      return dispatch({
        type: "NEW_ID",
        checkoutId: newCheckout.id,
        checkout: newCheckout,
        total: newCheckout.totalPrice,
      })
    } catch (error) {
      console.log("error:", error)
    }
  }

  async function removeFromCart(lineItemId) {
    dispatch({ type: "TOGGLE_LOADING" })
    const removedFromCart = [...state.cart].filter(
      (cartItem) => cartItem.id !== lineItemId
    )
    // console.log("removedFromCart:", removedFromCart)
    try {
      const checkout = await client.checkout.removeLineItems(state.checkoutId, [
        lineItemId,
      ])

      dispatch({
        type: "UPDATE_CHECKOUT",
        checkout,
        total: checkout.totalPrice,
        cart: removedFromCart,
      })
    } catch (e) {
      console.log("e:", e)
    }
  }

  async function updateCart(id, quantity) {
    dispatch({ type: "TOGGLE_LOADING" })

    const stateCart = [...state.cart]
    const index = stateCart.findIndex((el) => el.id === id)

    stateCart[index].quantity = quantity

    const update = { ...stateCart[index] }
    delete update.image
    delete update.price
    delete update.title
    delete update.variantId

    try {
      const checkout = await client.checkout.updateLineItems(state.checkoutId, [
        {
          id,
          quantity,
        },
      ])

      dispatch({ type: "UPDATE_CART", cart: stateCart })
      dispatch({
        type: "UPDATE_CHECKOUT",
        checkout,
        total: checkout.totalPrice,
        cart: stateCart,
      })
    } catch (e) {
      console.log("e:", e)
    }
  }

  async function addToCart(product, variantId, quantity = 1) {
    dispatch({ type: "TOGGLE_LOADING" })
    const extraData = {
      price: product.variants[0].price,
      image: product.images[0].localFile.childImageSharp.fluid,
      title: product.title,
      // id:
    }
    try {
      let toBeAdded = [...state.cart]
      const index = state.cart.findIndex((el) => el.variantId === variantId)
      if (index !== -1) {
        const updating = toBeAdded[index]
        const taDa = {
          ...updating,
          quantity: updating.quantity + quantity,
          ...extraData,
        }
        toBeAdded[index] = { ...taDa }
        // toBeAdded[index].quantity += quantity
      } else {
        toBeAdded = [...state.cart, { variantId, quantity, ...extraData }]
      }

      dispatch({ type: "ADD_TO_CART", cart: toBeAdded })

      const addItems = await client.checkout.addLineItems(state.checkoutId, [
        { variantId, quantity },
      ])

      const letsMerge = addItems.lineItems.map((el) => ({
        id: el.id,
        title: el.title,
      }))
      const newImprovedArr = mergeArr(toBeAdded, letsMerge)

      dispatch({
        type: "ADD_PRODUCT_TO_CART",
        cart: newImprovedArr,
        checkout: addItems,
        total: addItems.totalPrice,
      })
      toggleCart()
    } catch (e) {
      console.error(e)
    }
  }

  function mergeArr(a, b) {
    const toBeMerged = [...a]
    const toMerge = [...b]

    const res = []

    toBeMerged.forEach((product) => {
      toMerge.forEach((el) => {
        //

        if (product.title !== el.title) {
          return
        }

        const newObj = { ...product, id: el.id }
        res.push(newObj)
      })
    })

    return res
  }

  async function addCoupon(coupon) {
    dispatch({ type: "TOGGLE_LOADING" })

    const totalWithCoupon = await client.checkout.addDiscount(
      state.checkoutId,
      coupon
    )
    // console.log("totalWithCoupon:", totalWithCoupon)

    // dispatch({
    //   type: "UPDATE_CHECKOUT",
    //   checkout: totalWithCoupon,
    //   total: totalWithCoupon.totalPrice,
    //   cart: state.cart,
    // })
    return updateCheckoutFromCoupons(totalWithCoupon, coupon)
  }

  function updateCheckoutFromCoupons(totalWithCoupon, coupon) {
    if (!coupon) {
      couponUpdate(totalWithCoupon)
      return
    }
    const couponCheck =
      totalWithCoupon?.discountApplications?.[0]?.code === coupon
    if (!couponCheck) {
      couponUpdate(totalWithCoupon)

      return { msg: "This coupon code is not available", applied: false }
    }

    couponUpdate(totalWithCoupon)

    return {
      applied: true,
      msg:
        "New Coupon Applied. The total price of your checkout may have changed",
    }
  }

  function couponUpdate(totalWithCoupon) {
    dispatch({
      type: "UPDATE_CHECKOUT",
      checkout: totalWithCoupon,
      total: totalWithCoupon.totalPrice,
      cart: state.cart,
    })
  }

  async function removeCoupon(coupon) {
    dispatch({ type: "TOGGLE_LOADING" })

    const totalWithCoupon = await client.checkout.removeDiscount(
      state.checkoutId,
      coupon
    )
    return updateCheckoutFromCoupons(totalWithCoupon)
  }

  async function subscribe(email) {
    try {
      const { msg, result } = await addToMailChimp(email)
      if (result !== "success") {
        throw msg
      }

      setMessage(msg)

      return true
    } catch (e) {
      console.log("error message:", { e })
      setMessage(e.split("<a")[0])
      return false
    }
  }

  function setMessage(message) {
    dispatch({ type: "SET_MESSAGE", message })
    setTimeout(() => {
      dispatch({ type: "SET_MESSAGE", message: "" })
    }, 4000)
  }

  function getLineItem(size, el) {
    return size === 8
      ? el.variants.find((e) => e.title.includes("8")).shopifyId
      : el.variants.find((e) => e.title.includes("5")).shopifyId
  }

  async function cleanerRemoveFromCart(name) {
    dispatch({ type: "TOGGLE_LOADING", removing: true })

    // console.log("name:", name)
    // const inCaseSelected = name.includes("Welcome") ?  :null

    const products = state.cart.filter((el) => el.products[0].isBundle === name)
    // console.log("products:", products)

    // const products = state.cart.reduce((acc, val) => {
    //   // console.log("val:", val)

    //   if (val.products[0].isBundle === name) {
    //     console.log("name:", name)
    //     return [...acc, ...val.products]
    //   }
    //   return acc
    // }, [])

    try {
      if (products[0].name.includes("Welcome")) {
        const updater = { id: products[0].products[0].isWelcome, quantity: 0 }
        const checkout = await client.checkout.updateLineItems(
          state.checkoutId,
          [updater]
        )
        const total = checkout.totalPrice
        // console.log("updated:", updated)
        const newCart = [...state.cart].filter((el) => el.name !== name)

        return dispatch({
          type: "ADD_PRODUCT_TO_CART",
          checkout: graphModel,
          total,
          cart: newCart,
        })
      }

      const products2 = state.cart.reduce((acc, val) => {
        // console.log("val:", val)

        if (val.products[0].isBundle === name) {
          // console.log("name:", name)
          return [...acc, ...val.products]
        }
        return acc
      }, [])

      const updated = products2.reduce((acc, val) => {
        // console.log("val:", val)
        if (!acc[val.title]) {
          acc = {
            ...acc,
            [val.title]: { id: val.lineItem, quantity: 1, title: val.title },
          }
          return acc
        }
        if (acc[val].title) {
          acc[val.title].quantity++
        }

        return acc
      }, {})

      let graphModel = {}
      for (const product of Object.values(updated)) {
        const checkout = await client.checkout.updateLineItems(
          state.checkoutId,
          [{ id: product.id, quantity: 0 }]
        )
        graphModel = checkout
      }
      const total = graphModel.totalPrice
      const newCart = [...state.cart].filter((el) => el.name !== name)

      dispatch({
        type: "ADD_PRODUCT_TO_CART",
        checkout: graphModel,
        total,
        cart: newCart,
      })
    } catch (error) {
      console.log("error:", error)
      return startFresh("Ups something went wrong!")
    }
  }

  async function cleanerAddToCart(something, size, welcomeSelected) {
    /* console.log("welcomeSelected:", welcomeSelected)
    console.log("something:", something) */
    dispatch({ type: "TOGGLE_LOADING" })

    const sizeInfo =
      size === 8
        ? "Bundle Pick 8"
        : size === 5
        ? "Bundle Pick 5"
        : "Welcome Bundle"

    const completelyUniqueId = `${sizeInfo}-${Date.now().toString()}`
    try {
      const variantIdArr = welcomeSelected
        ? [
            {
              addBundle: {
                quantity: 1,
                variantId: welcomeSelected.variants[0].shopifyId,
                customAttributes: {
                  key: "bundle",
                  value: completelyUniqueId,
                },
              },
            },
          ]
        : something.map((el) => {
            const variantId = getLineItem(size, el)

            return {
              ...el,
              addBundle: {
                quantity: el.quantity,
                variantId,
                customAttributes: {
                  key: "bundle",
                  value: completelyUniqueId,
                },
              },
            }
          })

      let graphModel = {}
      for (const el of variantIdArr) {
        const added = await client.checkout.addLineItems(state.checkoutId, [
          el.addBundle,
        ])
        graphModel = added
      }

      const total = +graphModel.totalPrice

      const currentBundle = welcomeSelected
        ? graphModel.lineItems.find((el) =>
            el.customAttributes.find((one) => one.value === completelyUniqueId)
          )
        : graphModel.lineItems.filter((el) =>
            el.customAttributes.find((one) => one.value === completelyUniqueId)
          )

      const newCart = [
        ...state.cart,
        {
          name: completelyUniqueId,
          products: welcomeSelected
            ? something.map(mapArr)
            : variantIdArr.map(mapArr),
        },
      ]

      function mapArr(el) {
        return {
          ...el,
          isBundle: completelyUniqueId,
          parentBundle: sizeInfo,
          price: el.variants[0].price,
          image: el.images[0]?.localFile.childImageSharp.fluid,
          lineItemId: getLineItem(size, el),
          lineItem: currentBundle.length
            ? currentBundle.find(
                (product) =>
                  product.customAttributes[0].value === completelyUniqueId &&
                  product.title === el.title
              ).id
            : null,
          isWelcome: currentBundle.id,
        }
      }

      toggleCart()
      dispatch({
        type: "ADD_PRODUCT_TO_CART",
        checkout: graphModel,
        total,
        cart: newCart,
      })

    } catch (error) {
      console.log("error:", error)
    }
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultOptions,
        state,
        removeFromCart,
        updateCart,
        addCoupon,
        removeCoupon,
        addToCart,
        subscribe,
        toggleCart,
        cleanerAddToCart,
        cleanerRemoveFromCart,
      }}
    >
      {state.isLoading && !state.firstRender && !state.removing && (
        <PopUpLoading text="We are preparing your bundle!" />
      )}
      {state.isLoading && !state.firstRender && state.removing && (
        <PopUpLoading text="We are removing your bundle!" />
      )}
      {state.error && <PopUpLoading error={true} text={state.error} />}
      {children}
    </StoreContext.Provider>
  )
}

export function useStore() {
  return useContext(StoreContext)
}
